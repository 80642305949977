html{
  font-size: 0.83vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Broadcast Matter';
  src: url('./fonts/broadcast-matter.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@import url('//hello.myfonts.net/count/485f41');

@font-face {
  font-family: 'Nexa Rust Sans Black1';
  src: url('./fonts/nexa-rust-sans-black-1.woff2') format('woff2');
}
